import * as Sentry from '@sentry/react'
import Mixpanel from 'mixpanel-browser'

import { router } from './router'

export const initializeAnalytics = () => {
  if (import.meta.env.VITE_SENTRY_ACTIVE === 'true') {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [Sentry.tanstackRouterBrowserTracingIntegration(router)],
      tracesSampleRate: 0.8,
      // disable replays for now
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0,
    })
  }

  if (import.meta.env.VITE_MIXPANEL_ACTIVATED == 'true') {
    Mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
      loaded: mixpanel => {
        // use Mixpanel's device token for device identification
        localStorage.setItem('DEVICE_ID', mixpanel.get_property('$device_id'))
      },
    })
  }
}
