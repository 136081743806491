import { useNavigate } from '@tanstack/react-router'
import { FC, useMemo, useState } from 'react'

import { Button } from '@/components/button'
import { DetailButton } from '@/components/button/DetailButton'
import { Box } from '@/components/layout'
import { SVGIcon } from '@/components/svgIcon'
import { Description, Span, Title } from '@/components/typography'
import { usePayoutFee } from '@/hooks/usePayoutFee'
import { PaymentType } from '@/types/profile'

import { PaymentSelectionBadge } from './styles'

export const PaymentSelection: FC = () => {
  const navigate = useNavigate()
  const [paymentType, setPaymentType] = useState<PaymentType>()
  const { formattedFee } = usePayoutFee()

  const badgeText = useMemo(() => {
    return formattedFee ? `${formattedFee}` : ''
  }, [formattedFee])

  const handlePaymentTypeSelection = (pType: PaymentType) => {
    if (paymentType === pType) return setPaymentType(undefined)
    setPaymentType(pType)
  }

  const handleContinue = () => {
    if (paymentType === PaymentType.CARD)
      return navigate({ to: '/setup/terms-agreement' })
    if (paymentType === PaymentType.WALLET)
      return navigate({ to: '/setup/additional-info' })
  }
  return (
    <Box direction="column" align="start">
      <Title>Fast Pay, Your Way</Title>
      <Description size="sm" css={{ mt: '$16', mb: '$40' }}>
        Receive payouts instantly to either a digital wallet for{' '}
        <Span underlined italicized>
          free
        </Span>{' '}
        with the Branch App or to an existing bank account for a small fee.
      </Description>
      <DetailButton
        active={paymentType === PaymentType.WALLET}
        css={{ mb: '$16', width: '100%' }}
        icon={<SVGIcon iconName="branch" wrapperStyle={{ display: 'flex' }} />}
        handleOnClick={() => handlePaymentTypeSelection(PaymentType.WALLET)}
        title="Branch App"
        description="Get paid instantly to the Branch App, which includes a fee-free digital bank account, debit card, financial wellness tools, and more."
        badge={<PaymentSelectionBadge> Free </PaymentSelectionBadge>}
      />
      <DetailButton
        css={{ width: '100%' }}
        active={paymentType === PaymentType.CARD}
        icon={
          <SVGIcon iconName="credit_card" wrapperStyle={{ display: 'flex' }} />
        }
        handleOnClick={() => handlePaymentTypeSelection(PaymentType.CARD)}
        title={`Direct via a debit card`}
        description="Send earnings instantly to an existing personal bank account by linking your debit card."
        badge={<PaymentSelectionBadge> {badgeText} </PaymentSelectionBadge>}
      />
      <Button
        onClick={handleContinue}
        css={{ mt: '$40' }}
        disabled={!paymentType}
      >
        {paymentType ? 'Continue' : 'Select Option'}
      </Button>
    </Box>
  )
}
